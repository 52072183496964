import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "modal auto_adjust popup deletinfoPopup modal-outlined-gradient searchStockModel addPostSearchStockModel" }
const _hoisted_2 = { class: "modal--content autoAdjust outline min--width--30" }
const _hoisted_3 = { class: "modal--content modal--content-inner" }
const _hoisted_4 = { class: "modal--header modal--header-back d-flex align-items-center justify-content-between pb-0 mb-0" }
const _hoisted_5 = { class: "heading" }
const _hoisted_6 = { class: "modal--body pb-4" }
const _hoisted_7 = { id: "loginform" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-lg-10 col-xl-10 mx-auto" }
const _hoisted_10 = { class: "blockusercontent text-center mb-4" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "loginbtn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconComponent = _resolveComponent("IconComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "modal--backdrop",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dismiss(false)))
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_IconComponent, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dismiss(false))),
            icon: "back-icon-cover.svg",
            class: "backIconDv"
          }),
          _createElementVNode("h4", _hoisted_5, "Delete " + _toDisplayString(_ctx.title), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _cache[4] || (_cache[4] = _createElementVNode("p", null, "Are you sure you want to delete?", -1)),
                  (!_ctx.hideSubMessage)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_11, "You cannot undo it after deletion."))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("button", {
                    onClickOnce: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dismiss(true))),
                    class: "btn btn--primary mb-3"
                  }, " Confirm ", 32),
                  _createElementVNode("a", {
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.dismiss(false))),
                    href: "javascript:;"
                  }, "Cancel")
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}