declare type PluginInstallFunction = (app: any, ...options: any[]) => void;
import IconComponent from "@/components/IconComponent.vue";
import SparklineComponent from "@/components/SparklineComponent.vue";
import StockImageTag from "@/components/StockImageTag.vue";
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import { config } from 'jenesius-vue-modal';
import { createApp } from 'vue';
import InlineSvg from 'vue-inline-svg';
import VueSocialSharing from 'vue-social-sharing';
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Toast from "vue-toastification";
import { Quill, QuillEditor } from '@vueup/vue-quill';
import "vue-toastification/dist/index.css";
import draggable from "vuedraggable";
import App from './App.vue';
import './assets/scss/global.scss';
import './assets/scss/utility.scss';
import filters from './helpers/filters';
import setup from './interceptors/http.interceptor';
import './registerServiceWorker';
import router from './router/router';
import routerGuard from './router/router-guard';
import mitt from "mitt";
import MaterializeSelect from './components/material-select/MaterialSelect.vue';
import PlayBookItem from './views/private/profile-screen/components/PlayBookComponent/PlayBookItem.vue';
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import StockModule from 'highcharts/modules/stock';
import VueApexCharts from "vue3-apexcharts";
import VueSplide from '@splidejs/vue-splide';
import Plotly from 'plotly.js-dist-min'
import './aws-exports';
import { Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
setup(router);
routerGuard(router);
const app = createApp(App);
StockModule(Highcharts)
app.use(router);
app.use(Toast)
app.use(HighchartsVue as unknown as PluginInstallFunction)
app.use(VueSplide)
app.component('draggable', draggable);
app.component('SparklineComponent', SparklineComponent);
app.component('IconComponent', IconComponent);
app.component("apexchart", VueApexCharts)
app.component('StockImageTag', StockImageTag);
app.component('inline-svg', InlineSvg);
app.component('MaterialSelect', MaterializeSelect);
app.component('PlayBookItem', PlayBookItem);
app.component("QuillEditor", QuillEditor)
app.use(ElementPlus);
app.use(VueSocialSharing);
app.component('VCalendar', Calendar);
app.component('VDatePicker', DatePicker);
app.config.globalProperties.$plotly = Plotly;
app.config.globalProperties.$filters = filters;
app.config.globalProperties.$msalInstance = {};
app.config.globalProperties.emitter = mitt()
config({
    animation: undefined,
    scrollLock: true,
});
app.mount('#app');
