import { Amplify } from 'aws-amplify';
import Auth from '@aws-amplify/auth';
Amplify.configure({
    Auth: {
        Cognito: {
            userPoolClientId: '477rkvlhonqoq4qhr1fq9h989a',
            userPoolId: "us-east-2_Hv1wC1aQo",
            loginWith: {
                username: true,
                email: true,
                phone: false,
            }
            // mandatorySignIn: true,
        },
    },
});

