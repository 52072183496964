import { StorageKeys } from "@/helpers/enum";
import { QRCodeSetting } from "@/helpers/models/qrcode-setting.model";
import { RememberMeModel } from "@/helpers/models/rememberme.model";
import { ResendVerificationDTO } from "@/helpers/models/resendverification.dto";
import { UserProfileConfiguration } from "@/helpers/models/settings.model";
import SocialLinks from "@/helpers/models/sociallinks.model";
import User from "@/helpers/models/user.model";
import { ref } from "vue";
import utilService from "./util.service";
import router from "@/router/router";
import firebaseService from "./firebase.service";
import { useFCMNotification } from "@/composables/fcm.notification.composable";
import { ConfigurationModel } from "@/views/private/timeline/models/configuration.model";
import { SystemConfig } from "@/helpers/models/system-config";
import httpService from "./http.service";

class StorageService {
  private token: string | undefined;
  private sessionId: string | undefined;
  private isFirebaseLogin: boolean | undefined;
  private rememberMe: RememberMeModel | undefined;
  user = ref<User | undefined>();
  systemConfiguration: SystemConfig | undefined;
  resendVerifyEmailDTO: ResendVerificationDTO | undefined;
  followedStocks = ref<string[]>([]);
  images = {
    profilePicture: "",
    coverPhoto: "",
  };

  constructor() {
    this.getUser();
  }

  GetProperty(key: string) {
    var val: any = window.localStorage.getItem(key);
    if (val != "undefined") {
      return JSON.parse(val);
    } else {
      return val;
    }
  }

  SetProperty(key: string, value: any, parse = true) {
    if (parse) {
      window.localStorage.setItem(key, JSON.stringify(value));
    } else {
      window.localStorage.setItem(key, value);
    }
  }

  RemoveProperty(key: string) {
    window.localStorage.removeItem(key);
    return true;
  }

  RemoveAllProperties() {
    this.setToken("");
    this.setUser(new User());
    this.setIsFirebaseLogin(false);
    window.localStorage.clear();
    this.removeBackground();
    this.SetProperty(StorageKeys.rememberMe, this.rememberMe);
  }

  setToken(token: string) {
    this.token = token;
    this.RemoveProperty(StorageKeys.token);
    this.SetProperty(StorageKeys.token, this.token);
  }

  removeBackground() {
    var elId = "backgroundCont";
    var elem = document.getElementById(elId);
    if (elem) {
      elem.style.backgroundColor = "unset";
      elem.style.backgroundImage = "none";
    }
  }

  setBackground(user: User) {
    var elId = "backgroundCont";
    var elem = document.getElementById(elId);
    if (elem) {
      // var value = "#191527";
      elem.style.backgroundImage = `url(https://traderware.com/assets/img/png/night-sky-glows2.png)`;
      // if (user.websiteWallpaper) {
      //   value = `url(${user.websiteWallpaper})`;
      //   elem.style.backgroundColor = "unset";
      //   elem.style.backgroundImage = value;
      //   return;
      // } else if (user.websiteColor) {
      //   value = user.websiteColor;
      // }
      // elem.style.backgroundColor = value;
      // elem.style.backgroundImage = "none";
    }
  }

  getToken() {
    this.token = "";
    this.token = this.GetProperty(StorageKeys.token);
    if (!this.token) {
      this.token = "";
    }
    return this.token;
  }

  setSessionId(sessionId: string) {
    this.sessionId = sessionId;
    this.RemoveProperty(StorageKeys.sessionId);
    this.SetProperty(StorageKeys.sessionId, this.sessionId);
  }

  getSessionId() {
    this.sessionId = "";
    this.sessionId = this.GetProperty(StorageKeys.sessionId);
    if (!this.sessionId) {
      this.sessionId = "";
    }
    return this.sessionId;
  }

  setIsFirebaseLogin(isFirebaseLogin: boolean) {
    this.isFirebaseLogin = isFirebaseLogin;
    this.RemoveProperty(StorageKeys.isFirebaseLogin);
    this.SetProperty(StorageKeys.isFirebaseLogin, this.isFirebaseLogin);
  }

  getIsFirebaseLogin() {
    this.isFirebaseLogin = false;
    this.isFirebaseLogin = this.GetProperty(StorageKeys.isFirebaseLogin);
    return this.isFirebaseLogin;
  }

  setUser(user: any) {
    this.user.value = new User();
    Object.assign(this.user.value, user);
    this.RemoveProperty(StorageKeys.user);
    this.SetProperty(StorageKeys.user, this.user.value);
    this.setImages();
  }

  setImages() {
    if (this.user.value && this.user.value._id && utilService) {
      if (this.user.value.profilePicture) {
        this.images.profilePicture = utilService.getUserProfilePicture(
          this.user.value?._id!,
          this.user.value?.profilePicture!,
          "male",
          false,
          false
        );
      }
      if (this.user.value.coverPhoto) {
        this.images.coverPhoto = utilService.getUserProfilePicture(
          this.user.value?._id!,
          this.user.value?.coverPhoto!,
          "male",
          true,
          false
        );
      }
      this.user.value.images = this.images;
    }
  }

  getUser(): User {
    this.user.value = this.GetProperty(StorageKeys.user);
    if (!this.user.value) {
      this.user.value = new User();
    }
    if (!this.user.value.qrcode) {
      this.user.value.qrcode = new QRCodeSetting();
    }
    if (!this.user.value.socialLinks || !this.user.value.socialLinks.length) {
      if (this.user.value.socialLinks) {
        this.user.value.socialLinks = SocialLinks.initializeArrayWithData(
          this.user.value.socialLinks
        );
      } else {
        this.user.value.socialLinks = SocialLinks.initialArray;
      }
    } else {
      var initialArray = SocialLinks.initialArray;
      for (let link of this.user.value.socialLinks) {
        if (!link.category) {
          link.category = initialArray.find(
            (x) => x.platform == link.platform
          )?.category;
        }
      }
    }
    // if (!this.user.value.trading) {
    //   this.user.value.trading = [];
    // }
    // if (!this.user.value.favouriteStocks) {
    //   this.user.value.favouriteStocks = [];
    // }
    // if (!this.user.value.stats || !this.user.value.stats.length) {
    //   this.user.value.stats = [
    //     { label: "Followers", value: false },
    //     { label: "Investments", value: false },
    //     { label: "Invested", value: false },
    //     { label: "Views", value: false },
    //     { label: "Outreach", value: false },
    //     { label: "Avg Gains", value: false },
    //     { label: "Top Gains", value: false },
    //     { label: "Avg Loss", value: false },
    //     { label: "Top Loss", value: false },
    //   ];
    // }
    if (this.user.value.profileConfiguration) {
      for (let config of new UserProfileConfiguration().widgets) {
        var exInd = this.user.value.profileConfiguration.widgets.findIndex(
          (x) => x.type == config.type
        );
        if (exInd == -1) {
          this.user.value.profileConfiguration.widgets.push(config);
        }
      }
    } else {
      this.user.value.profileConfiguration = new UserProfileConfiguration();
    }
    if (this.user.value._id) {
      this.setImages();
      this.user.value.images = this.images;
    }
    return this.user.value;
  }

  async logout() {
    try {
      const { unregister } = useFCMNotification();
      unregister();
    } catch (e) {}
    this.RemoveAllProperties();
    document.body.click();
    router.push({ name: "signin" });
    try {
      firebaseService.logout();
    //   this.getTheme()
    } catch (e) {}
  }

  setRememberMe(rememberMe: RememberMeModel) {
    this.rememberMe = new RememberMeModel();
    this.rememberMe = Object.assign(this.rememberMe, rememberMe);
    this.RemoveProperty(StorageKeys.rememberMe);
    this.SetProperty(StorageKeys.rememberMe, this.rememberMe);
  }
  getRememberMe(): RememberMeModel {
    this.rememberMe = new RememberMeModel();
    this.rememberMe = this.GetProperty(StorageKeys.rememberMe);
    if (!this.rememberMe) {
      this.rememberMe = new RememberMeModel();
    }
    return this.rememberMe;
  }

  getUserConfiguration() {
    var user = this.getUser();
    if (user && user.configuration) {
      return user.configuration;
    }
    return new ConfigurationModel();
  }

  setSystemConfiguration(systemConfigurationData: SystemConfig) {
    this.systemConfiguration = new SystemConfig();
    this.systemConfiguration = Object.assign(
      this.systemConfiguration,
      systemConfigurationData
    );
    this.RemoveProperty(StorageKeys.systemConfiguration);
    this.SetProperty(StorageKeys.systemConfiguration, this.systemConfiguration);
  }

  getSystemConfiguration(): SystemConfig {
    this.systemConfiguration = new SystemConfig();
    this.systemConfiguration = this.GetProperty(
      StorageKeys.systemConfiguration
    );
    if (!this.systemConfiguration) {
      this.systemConfiguration = new SystemConfig();
    }
    return this.systemConfiguration;
  }
  setTheme(val){
    this.SetProperty('appTheme', val)
  }
  getTheme(){
    return this.GetProperty('appTheme')
  }
  getDeletedChatIds(): string[] {
    return this.GetProperty(StorageKeys.deletedChatId) ?? [];
  }

  setDeletedChatIds(chatId: string) {
    let ids = this.getDeletedChatIds();
    if (!ids.includes(chatId)) {
      ids.push(chatId);
    }
    this.SetProperty(StorageKeys.deletedChatId, ids);
    return ids;
  }

  removeDeletedChatIds(chatId: string) {
    let ids = this.getDeletedChatIds();
    if (ids.includes(chatId)) {
      ids.splice(ids.indexOf(chatId), 1);
    }
    this.SetProperty(StorageKeys.deletedChatId, ids);
    return ids;
  }
  
}

export default new StorageService();
