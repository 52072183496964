import { SocketStock } from "@/helpers/models/stock/socket_stock.model";
import { StockSocketTransferDTO } from "@/helpers/models/stock/stock_socket_transfer.dto";
import utilService from "@/services/util.service";
import { Socket, io } from "socket.io-client";
import { onMounted, ref, watchEffect } from "vue";

let socket: Socket;
const userDataInfo = ref<any>({});
const userIds = ref<any>([]);
var stockIds: StockSocketTransferDTO[] = [];
var stocksData: any = {};
var stocks: any = [];
const predicitionDataInfo = ref<any>({});
const predictionIds = ref<any>([]);

export const useStockSocket = () => {
  var timeout: any;

  const debounceOnInput = (func: any, ms: number = 1000) => {
    clearTimeout(timeout);
    timeout = setTimeout((e: any) => func(e), ms);
  };

  const connectToSocket = () => {
    if (!socket) {
      socket = io(utilService.getLiveHostURL());
      socket.on("connect", () => {
        listenStockFromStockIds();
        socket.on("priceupdate", (data) => {
          if (stocks) {
            let obj = JSON.parse(data);
            let findingStockIndex = stocks.findIndex((x: any) => x.symbol === obj.symbol);
            if (findingStockIndex !== -1 && stocks[findingStockIndex].exchange !== "CRYPTO") {
              // if (stocksData[stocks[findingStockIndex]["stockId"]]?.changesPercentage !== obj.changesPercentage) {
              //   stocksData[stocks[findingStockIndex]["stockId"]].changesPercentage = obj.changesPercentage;
              // }
              let changePrice:any = (((obj.price - stocksData[stocks[findingStockIndex]["stockId"]].price) / stocksData[stocks[findingStockIndex]["stockId"]].price) * 100).toFixed(2)
              if(changePrice != 0){
                stocksData[stocks[findingStockIndex]["stockId"]].changesPercentage = changePrice;
              };

              if (stocksData[stocks[findingStockIndex]["stockId"]]?.price !== obj.price) {
                stocksData[stocks[findingStockIndex]["stockId"]].price = obj.price;
              }
            }
          }
        });
        getUsersByID();
        socket.on("users", (response) => {
          if (response) {
            response.forEach((data: any) => {
              userDataInfo.value[data._id] = data;
            });
          }
        });
        getPredictionsByID();
        socket.on("getPredictionsByID", (response) => {
          if (response) {
            response.forEach((data: any) => {
              predicitionDataInfo.value[data.predictionId] = data;
            });
          }
        });
      });
      socket.on("disconnect", () => {});
      socket.on("connect_error", (error) => {});
    }
  };

  const disconnectFromSocket = () => {
    socket.removeAllListeners();
    socket.close();
  };

  var count = 1;

  const listenStockFromStockIds = () => {
    if (socket.connected && !document.hidden) {
      if (stockIds.length) {
        count++;
        socket.emit(
          "getstocksdata",
          JSON.stringify({ stocks: stockIds }),
          (data: any) => {
            if (data && data.length) {
              for (let stock of data) {
                if (stock && stock.stockId) {
                  let index = stocks.findIndex(
                    (x: any) => x.stockId === stock.stockId
                  );
                  if (index === -1) {
                    stocksData[stock.stockId] = stock;
                    stocks.push(stock);
                  }
                }
              }
            }
          }
        );
      }
    }
    setTimeout(() => {
      listenStockFromStockIds();
    }, 3000);
  };

  const addStockId = (stockObj: StockSocketTransferDTO) => {
    if (stockObj) {
      // stockObj.showTrendChart
      checkStockExistence(stockObj.stockId!, true);
    }
  };

  const clearStockIds = () => {
    stockIds = [];
  };

  const getDataByStockId = (stockId: string) => {
    if (stocksData && stocksData[stockId]) {
      return stocksData[stockId];
    }
    return null;
  };

  const checkStockExistence = (stockId: string, showChart: boolean = false) => {
    if (stockId) {
      var index = stockIds.findIndex((x) => x.stockId == stockId);
      if (index == -1) {
        stockIds.push(new StockSocketTransferDTO(stockId, showChart));
      } else if (stockIds[index].showTrendChart != showChart) {
        stockIds[index].showTrendChart = showChart;
      }
      return true;
    }
    return null;
  };

  const searchStocks = (keyword: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        socket.emit("searchStockByKeyword", keyword, (data: SocketStock[]) => {
          if (!data) {
            data = [];
          }
          resolve(data.slice(0, 40));
        });
      } catch (e) {
        resolve([]);
      }
    });
  };

  const getUsersByID = () => {
    if (socket && socket.connected && userIds.value.length) {
      count++;
      socket.emit("users", JSON.stringify(userIds.value), (response: any) => {
        if (response) {
          if (response.length > 0) {
            response.forEach((data: any) => {
              userDataInfo.value[data._id] = data;
            });
          }
        }
      });
    }
  };

  const clearUserIds = () => {
    userIds.value = [];
    userDataInfo.value = {};
  };

  // watchEffect(() => {
  //   if (userIds.value.length) {
  //     getUsersByID();
  //   }
  // });

  const getPredictionsByID = () => {
    if (socket && socket.connected && predictionIds.value.length) {
      count++;
      socket.emit(
        "getPredictionsByID",
        JSON.stringify(predictionIds.value),
        (response: any) => {
          if (response) {
            response.forEach((data: any) => {
              predicitionDataInfo.value[data.predictionId] = data;
            });
          }
        }
      );
    }
  };

  const clearPredictionIds = () => {
    predictionIds.value = [];
    predicitionDataInfo.value = {};
  };

  // watchEffect(() => {
  //   if (predictionIds.value.length) {
  //     getPredictionsByID();
  //   }
  // });

  return {
    getUsersByID,
    clearUserIds,
    userDataInfo,
    userIds,
    getPredictionsByID,
    clearPredictionIds,
    predicitionDataInfo,
    predictionIds,
    socket,
    debounceOnInput,
    stockIds,
    searchStocks,
    connectToSocket,
    disconnectFromSocket,
    addStockId,
    clearStockIds,
    getDataByStockId,
    checkStockExistence,
  };
};
