<template>
  <div class="modal auto_adjust popup deletinfoPopup modal-outlined-gradient searchStockModel addPostSearchStockModel">
    <div class="modal--backdrop" @click="dismiss(false)"></div>
    <div class="modal--content autoAdjust outline min--width--30">
      <div class="modal--content modal--content-inner">
        <div class="modal--header modal--header-back d-flex align-items-center justify-content-between pb-0 mb-0">
          <IconComponent @click="dismiss(false)" icon="back-icon-cover.svg" class="backIconDv" />
          <h4 class="heading">Delete {{ title }}</h4>
        </div>
        <div class="modal--body pb-4">
          <div id="loginform">
            <!-- Form -->
            <div class="row">
              <div class="col-lg-10 col-xl-10 mx-auto">
                <div class="blockusercontent text-center mb-4">
                  <p>Are you sure you want to delete?</p>
                  <p v-if="!hideSubMessage">You cannot undo it after deletion.</p>
                </div>

                <div class="loginbtn">
                  <button @click.once="dismiss(true)" class="btn btn--primary mb-3">
                    Confirm
                  </button>
                  <a @click="dismiss(false)" href="javascript:;">Cancel</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { popModal } from "jenesius-vue-modal";
import { defineComponent } from "vue";

export default defineComponent({
  props: ["title", "hideSubMessage"],
  components: {},
  setup(props, context) {
    const dismiss = async (val: boolean) => {
      context.emit("close", val);
      popModal();
    };

    return {
      dismiss,
    };
  },
});
</script>
<style lang="scss" scoped>
.deletinfoPopup {
  >.modal--content {
    position: absolute !important;
  }

  .modal--content {
    width: 420px;
    background: var(--base-regular) 0 0 no-repeat padding-box;
    box-shadow: 0 11px 43px var(--color-grey2);
    border-radius: 10px;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-flow: column;
    max-height: max-content;
    height: auto;
  }

  .modal--header {
    padding: 20px;
    color: var(--text-color);
    text-align: center !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    border-bottom: 1px solid hsla(0, 0%, 43.9%, 0.3019607843);

    h4 {
      font-size: var(--font-2sm);
      font-weight: 600;
      margin: 0px;
      line-height: 1;
      letter-spacing: 0px;
      padding: 0px;
      color: var(--white-color);
      text-align: center;
    }
  }

  .modal--body {
    flex-grow: 1;
    overflow: hidden auto;
    padding: 0 20px;
    margin-block: 20px;

    p {
      font-weight: 400;
      letter-spacing: 0px;
      color: var(--color-new-light-grey4);
      margin-bottom: 0.5rem;
      display: inline-block;
      font-size: var(--font-2sm);
      margin: 0px 0px 10px;
      padding: 0px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .loginbtn button {
      background: linear-gradient(45deg, #b946d7 0%, #2aafdc 100%);
      padding: 10px 0;
      font-weight: 500;
      margin-bottom: 1rem !important;
    }

    a {
      text-decoration: none;
    }
  }

  .pb-4 {
    padding-bottom: 1.5rem !important;
  }
}

.loginbtn {
  button {
    background: linear-gradient(45deg, #b946d7 0%, #2aafdc 100%);
    border: 1px solid var(--light-brown2);
    padding: 10px 0;
    border-radius: 10px;
    font-weight: 500;
    color: var(--text-color);
  }
}

.loginbtn {
  a {
    border: 1px solid var(--text-color);
    padding: 10px 0;
    border-radius: 10px;
    font-weight: 500;
    text-align: center;
    color: var(--text-color);
    background-color: transparent;
    width: 100%;
    display: inline-block;
  }
}

.blockusercontent {
  p {
    color: var(--base-text-white) !important;
    font-size: var(--font-lg);
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1891px) {
  .modal--body {
    .blockusercontent {
      p {
        font-size: var(--font-xs);

        &:first-child {
          margin-bottom: 0;
        }
      }
    }

    .loginbtn {
      a {
        padding: 7px 0;
        font-size: var(--font-2xs);
        height: 36px;
      }
    }
  }
}
</style>
