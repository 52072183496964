import { AddSectionDTO } from "@/composables/section/models/add-section.dto";
import { Section } from "@/composables/section/models/section.model";
import { UpdateSectionDTO } from "@/composables/section/models/update-section.dto";
import ForgotPasswordModel from "@/helpers/models/forgotpassword.model";
import { LoginModel } from "@/helpers/models/login.model";
import { ResendVerificationDTO } from "@/helpers/models/resendverification.dto";
import { ResetPasswordModel } from "@/helpers/models/resetpassword.model";
import {
  SignupModel,
  SignupWaitlistModel,
} from "@/helpers/models/signup.model";
import { VerifyFirebaseToken } from "@/helpers/models/verify_firebase";
import api from "@/interceptors/api";
import { PredictionOHLC } from "@/views/private/profile-screen/components/PredictionOHLC/dto/prediction-ohlc.model";
import PostsModel from "@/views/private/timeline/models/posts.model";
import UpdatePostModel from "@/views/private/timeline/models/updatepost.model";
import CommentsModel from "@/views/private/timeline/models/comments.model";
import { LikePostModel } from "@/views/private/timeline/models/likepost.model";
import { UnlikeModel } from "@/views/private/timeline/models/unlike.model";
import cacheApi from "@/interceptors/cache-api";
import AddTopFriendDTO from "@/components/header/search-container/addtopfriendsdto";
import { FollowStockModel } from "../composables/followstock.model";
import SocialAccountVerificationDTO from "@/components/social-media/socialAccountVerificationDto";
import { UpdateConversationRequest } from "@/helpers/models/messenger/update-conversation-request.dto";
import { StartChatDTO } from "@/helpers/models/messenger/start-conversation.dto";
import { Message } from "@/helpers/models/messenger/message.model";

class HttpService {

  public async sendMessage(
    chatId: string,
    body: Message,
    optQuery: string = ""
  ) {
    const response = await api.post(
      `/conversation/message/${chatId}${optQuery}`,
      body
    );
    return response.data;
  }

  public async startChat(body: StartChatDTO) {
    const response = await api.post(`/conversation`, body);
    return response.data;
  }

  public async updateConnectionRequestStatus(
    conversationId: string,
    body: UpdateConversationRequest
  ) {
    const response = await api.put(
      `/conversation/request/${conversationId}`,
      body
    );
    return response.data;
  }

  public async getConversationById(conversationId: string) {
    const response = await api.get(`/conversation/${conversationId}`);
    return response.data;
  }

  public async muteNotification(conversationId: string) {
    const response = await api.put(
      `/conversation/notification/mute/${conversationId}`
    );
    return response.data;
  }

  public async unmuteNotification(conversationId: string) {
    const response = await api.put(
      `/conversation/notification/unmute/${conversationId}`
    );
    return response.data;
  }

  public async getSystemConfig() {
    const response = await api.get("/system-config");
    return response.data;
  }
  public async signUpForWaitlist(data: SignupWaitlistModel) {
    const response = await api.post("/waitlist", data);
    return response.data;
  }
  public async getWaitlistVerified(data: any) {
    const response = await api.get(`/waitlist/${data}`);
    return response.data;
  }
  public async signUp(data: SignupModel) {
    const response = await api.post("/auth/multiRegister", data);
    return response.data;
  }
  public async login(data: LoginModel) {
    const response = await api.post("/auth/multiLogin", data);
    return response.data;
  }
  public async gettwitterAuthUrlV1() {
    const response = await api.get("/twitter/v1/authUrl");
    return response.data;
  }
  public async handletwitterauth(data: any) {
    const response = await api.post("/twitter/v2/authenticate", data);
    return response.data;
  }

  public async handletwitterauthV1(data: any) {
    const response = await api.post("/twitter/v1/authenticate", data);
    return response.data;
  }

  public async sharePostOnTwitter(data: any) {
    const response = await api.post(`/profile/shareToTwitter`, data);
    return response.data;
  }

  public async resendVerificationLink(data: ResendVerificationDTO) {
    let response = await api.post(`/auth/resendVerificationEmail`, data);
    return response;
  }

  public async forgotPassword(data: ForgotPasswordModel) {
    const response = await api.post("/auth/forgotpassword", data);
    return response.data;
  }

  public async resetPassword(data: ResetPasswordModel) {
    const response = await api.post("/auth/resetpassword", data);
    return response.data;
  }

  public async verifyEmail(data: any) {
    const response = await api.post("/auth/verifyemail", data);
    return response.data;
  }

  public async verifyPhoneNumber(data: VerifyFirebaseToken) {
    const response = await api.post("/auth/verifyPhoneNumber", data);
    return response.data;
  }

  public async validateUsername(data: any) {
    const response = await api.post("/auth/validateusername", data);
    return response?.data;
  }

  // public async changePassword(data: ChangePasswordModel) {
  //   const response = await api.post("/auth/changepassword", data);
  //   return response.data;
  // }

  public async getUser(userName: string) {
    const response = await api.get(`/external/user/${userName}`);
    return response.data;
  }

  public async getUserById(userId: string) {
    const response = await api.get(`/users/byId/${userId}`);
    window.localStorage.setItem(
      "discord",
      JSON.stringify(response.data.data.discord)
    );
    return response.data;
  }

  public async updateUser(data: any) {
    const response = await api.put(`/users`, data);
    return response.data;
  }

  public async updateUserAfterConfiguration(data: any) {
    const response = await api.put(`/users/updateConfiguration`, data);
    return response.data;
  }

  public async getTopNasdaqStocks() {
    const response = await api.get(`/stocks/topNasdaq`);
    return response.data;
  }

  public async getSocialStocksCounter() {
    const response = await api.get(`/favourite-investment/counts`);
    return response.data;
  }

  public async getSocialStocks(query?: any) {
    const response = await api.get(`/favourite-investment?${query}`);
    return response.data;
  }

  public async getBlockUsers() {
    const response = await api.get(`/users/blockedusers`);
    return response.data;
  }

  public async addSection(data: AddSectionDTO) {
    const response = await api.post(`/section`, data);
    return response.data;
  }

  public async updateSection(id: string, data: UpdateSectionDTO) {
    const response = await api.patch(`/section/${id}`, data);
    return response.data;
  }

  public async getAllSections(userId: string) {
    const response = await api.get(`/section/user/${userId}`);
    return response.data;
  }

  public async updateSectionOrders(data: any[]) {
    const response = await api.patch(`/section/all`, data);
    return response.data;
  }

  public async getQrCode(data: any) {
    const response = await api.post(`/qrcode/generate`, data);
    return response.data;
  }

  public async searchProfile(query: any) {
    const response = await api.get(`users/searchUser${query}`);
    return response;
  }

  public async recentSearchProfile() {
    const response = await api.get(`recent-searches`);
    return response;
  }

  public async deleteRecentSearchProfile(id: any) {
    const response = await api.delete(`/recent-searches/${id}`);
    return response;
  }

  public async updatingSettings(data: any) {
    const response = await api.patch(`/user-setting/widget`, data);
    return response;
  }

  public async getFilteredUser(query: any) {
    const response = await api.get(`/leaderboard/filterUser${query}`);
    return response;
  }

  public async addSearchHistory(data: any) {
    const response = await api.post("/searchHistory", data);
    return response;
  }

  public async getSearchHistory() {
    const response = await api.get(`/searchHistory`);
    return response;
  }

  public async getFriendUser(query: any) {
    const response = await api.get(`/leaderboard/userfriends/${query}`);
    return response;
  }

  public async getFollowedUser(query: any) {
    const response = await api.get(`/leaderboard/userFollowers/${query}`);
    return response;
  }

  public async getOtherUserFollowedUser(query: any, id: string) {
    const response = await api.get(`/leaderboard/userFollowers/${id}/${query}`);
    return response;
  }

  public async getFollowingUser(query: any) {
    const response = await api.get(`/leaderboard/userFollowing/${query}`);
    return response;
  }
  public async getOtherUserFollowingUser(query: any, id: string) {
    const response = await api.get(`/leaderboard/userFollowing/${id}/${query}`);
    return response;
  }

  public async getAllUserOfOtherUser(query: any, id: string) {
    const response = await api.get(`/leaderboard/myall/${id}/${query}`);
    return response;
  }

  public async getUserFollowAndFollowingCount() {
    const response = await api.get(`/users/connectionscount`);
    return response;
  }

  public async getFollowedInfluencers(data: any) {
    const response = await api.post(`/section/influencers/get`, data);
    return response;
  }

  public async unFollow(data: any) {
    const response = await api.post("/users/unfollow", data);
    return response;
  }

  public async addConnection(data: any) {
    const response = await api.post("/connections", data);
    return response;
  }

  public async cancelRequest(data: any) {
    const response = await api.post(`/connections/cancelrequest`, data);
    return response;
  }

  public async removeFriend(data: any) {
    const response = await api.post(`/connections/removefriend`, data);
    return response;
  }

  public async updateRequestConnection(connectionId: any, status: string) {
    const response = await api.put(
      `/connections/${connectionId}/status/${status}`
    );
    return response;
  }

  public async updateSocialLinks(data: any) {
    const response = await api.patch("/section/sociallinks", data);
    return response;
  }

  // FCM Notification

  public async registerFCMNotification(deviceId: string, fcmToken: string) {
    const response = await api.patch("/eventnotification", {
      deviceId,
      fcmToken,
    });
    return response;
  }

  public async unregisterFCMNotification(deviceId: string, fcmToken: string) {
    const response = await api.put("/eventnotification/deleteNotification", {
      deviceId,
      fcmToken,
    });
    return response;
  }

  public async readNotification(notificationIds: string[]) {
    const response = await api.put("/eventnotification/readnotification", {
      ids: notificationIds,
    });
    return response;
  }

  public async readNotificationByType(sectionType: string) {
    const response = await api.put(
      `/eventnotification/readnotification/${sectionType}`
    );
    return response;
  }

  public async getNotificationSettings() {
    const response = await api.get("/notification-permission");
    return response;
  }
  public async notificationSettings(data: any) {
    const response = await api.put("/notification-permission", data);
    return response;
  }

  // End of FCM Notification

  public async getProfileData(username: string) {
    const response = await api.get(`/profile/${username}`);
    return response;
  }

  public async getSparklineData(
    stockId: string,
    startDate: number,
    endDate: number
  ) {
    const response = await api.get(
      `chart/sparkline/range?stockId=${stockId}&startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  }

  // Wallpaper

  public async getWallpaperForUser() {
    const response = await api.get(`users/wallpapers`);
    return response.data;
  }

  public async addWallpaper(url: string) {
    const response = await api.post(`users/addWallpaper`, { url });
    return response.data;
  }

  public async updateWallpaper(websiteColor: string, websiteWallpaper: string) {
    const response = await api.patch(`users/updateWallpaper`, {
      websiteWallpaper,
      websiteColor,
    });
    return response.data;
  }

  // End of Wallpaper

  public async createWatchList(data: any) {
    const response = await api.post(`/watchlist`, data);
    return response.data;
  }

  public async updateWatchList(data: any) {
    const response = await api.put(`/watchlist`, data);
    return response.data;
  }

  public async deleteWatchList(data: any) {
    const response = await api.delete(`/watchlist`, data);
    return response.data;
  }

  public async getPublicWatchList(userId: any) {
    const response = await api.get(`/watchlist/user/${userId}`);
    return response;
  }

  public async deleteWatchlistByIds(ids: string[]) {
    const response = await api.post(`/watchlist/deleteMany`, ids);
    return response;
  }

  public async getAllWatchlistByToken() {
    const response = await api.get(`/watchlist`);
    return response.data;
  }

  public async getOHLCCardData(data: PredictionOHLC) {
    const response = await api.post(`/prediction/ohlc`, data);
    return response.data;
  }

  public async getPrivateWatchList() {
    const response = await api.get(`/watchlist`);
    return response;
  }
  public async deleteWatchlist(id: any) {
    const response = await api.delete(`/watchlist/removewatchlist/${id}`);
    return response;
  }
  public async updateWatchlistStatus(watchlistId: string, status: string) {
    const response = await api.put(
      `/watchlist/${watchlistId}/status/${status}`
    );
    return response;
  }
  public async updateMultipleWatchlistStatus(data: any) {
    const response = await api.post(`/watchlist/multiple/status`, {
      list: data,
    });
    return response.data;
  }

  public async getWatchlistByIds(watchlistIds: string[]) {
    const response = await api.post(`/watchlist/byIds`, watchlistIds);
    return response.data;
  }

  public async getSelection(data: any) {
    const response = await api.post(`/profile/saveSection`, data);
    return response.data;
  }

  public async createPlaybook(data: any) {
    const response = await api.post(`/playbook`, data);
    return response.data;
  }

  public async updatePlaybook(id: any, data: any) {
    const response = await api.patch(`/playbook/${id}`, data);
    return response.data;
  }

  public async updateMutiplePlaybook(data: any) {
    const response = await api.patch(`/playbook/multiple`, data);
    return response.data;
  }

  public async deletePlaybook(id: any) {
    const response = await api.delete(`/playbook/${id}`);
    return response;
  }

  public async deletePlaybookByStocks(data: any) {
    const response = await api.post(`/playbook/deletePlaybookByStocks`, data);
    return response;
  }

  public async getPredicationData(id: any) {
    const response = await api.post(`/prediction/fetch/${id}`);
    return response;
  }

  public async getPredicationOhlcData(id: any) {
    const response = await api.post(`/prediction-ohlc/fetch/${id}`);
    return response;
  }

  public async getFavouriteInvestmentData(id: any, data: any) {
    const response = await api.patch(
      `/section/favouriteInvestment/${id}`,
      data
    );
    return response;
  }

  public async getDashboardFilterCountData() {
    const response = await api.get(`/leaderboard/diversificationCount`);
    return response;
  }

  public async getLeaderboardData(period: string) {
    const periodString = `period=${encodeURIComponent(period)}`;
    const response = await api.get(`/prediction-leaderboard?${periodString}`);
    return response.data;
  }

  public async socialSignup(data: any) {
    const response = await api.post(`/auth/sociallogin`, data);
    return response;
  }

  public async updateMutipleProfitAndLoss(data: any) {
    const response = await api.patch(`/profit-and-loss/multiple`, data);
    return response.data;
  }

  public async addProfitAndLoss(data: any) {
    const response = await api.post(`/profit-and-loss/trade`, data);
    return response.data;
  }

  public async updateProfitAndLoss(id: any, data: any) {
    const response = await api.patch(`/profit-and-loss/trade/${id}`, data);
    return response.data;
  }

  public async deleteProfitAndLoss(id: any) {
    const response = await api.delete(`/profit-and-loss/${id}`);
    return response.data;
  }

  public async getAllTradeForProfitAndLossByUserId() {
    const response = await api.get(
      `/profit-and-loss/ProfitAndLossDataForUserId`
    );
    return response.data;
  }

  public async addViewerWatchlist(id: any) {
    const response = await api.post(`/watchlist/addViewer/${id}`);
    return response.data;
  }

  public async reportUser(data: any) {
    const response = await api.put(`/users/reportUser`, data);
    return response.data;
  }

  public async getUngroupedPlaybook(userId: string) {
    const response = await api.get(`/playbook/ungrouped/${userId}`);
    return response.data;
  }

  public async getBestTradeByUserId(data: any) {
    const response = await api.put(`profit-and-loss/profitAndLossByIds`, data);
    return response;
  }

  public async addPredictionOhlc(data: any) {
    const response = await api.post(`/prediction-ohlc`, data);
    return response.data;
  }

  public async addPrediction(data: any) {
    const response = await api.post(`/prediction`, data);
    return response.data;
  }

  public async deletePredictionOHLC(id: any) {
    const response = await api.delete(`/prediction-ohlc/${id}`);
    return response.data;
  }

  public async deletePrediction(id: any) {
    const response = await api.delete(`/prediction/${id}`);
    return response.data;
  }

  public async getAllPredictionsByUserId() {
    const response = await api.get(`/prediction/all`);
    return response.data;
  }

  public async updatePredictionOhlc(id: any, data: any) {
    const response = await api.patch(`/prediction-ohlc/${id}`, data);
    return response.data;
  }

  public async updatePrediction(id: any, data: any) {
    const response = await api.patch(`/prediction/${id}`, data);
    return response.data;
  }

  public async updateMutiplePrediction(data: any) {
    const response = await api.patch(`/prediction/multiple`, data);
    return response.data;
  }

  public async updateMutiplePredictionOhlc(data: any) {
    const response = await api.patch(`/prediction-ohlc/multiple`, data);
    return response.data;
  }

  public async deleteUserAccount() {
    const response = await api.patch(`/users/delete`);
    return response.data;
  }

  public async changePassword(data: any) {
    const response = await api.post(`/users/changepassword`, data);
    return response.data;
  }

  public async getLinkedInAuthUrl() {
    const response = await api.get("/linkdin-autherization");
    return response.data;
  }
  public async handleLinkedInAuth(data: any) {
    const response = await api.post(
      "/linkdin-autherization/authenticateWithLinkdin",
      data
    );
    return response.data;
  }

  public async getRedditAuthUrl() {
    const response = await api.get("/reddit/authUrl");
    return response.data;
  }

  public async handleRedditAuth(queryParams: any) {
    // Extract code and state from the queryParams object
    const code = queryParams.code;
    const state = queryParams.state;
    // Construct the query string
    const queryString = `code=${encodeURIComponent(
      code
    )}&state=${encodeURIComponent(state)}`;
    // Make the GET request to the server with the query string appended to the URL
    const response = await api.get(
      `/reddit/authorizeWithReddit?${queryString}`
    );
    return response.data;
  }

  public async sharePostOnSocial(data: any) {
    const response = await api.post(`/profile/external-share`, data);
    return response.data;
  }

  public async updateUserProfile(data: any) {
    const response = await api.put(`/users/updateprofile`, data);
    return response.data;
  }

  public async getEmailVerificationCode(email: string) {
    const response = await api.put(
      `/users/emailVerificationCode?email=${email}`
    );
    return response.data;
  }

  public async EmailVerificationPosting(data: any) {
    const response = await api.post(`/users/verifyEmail`, data);
    return response.data;
  }

  public async twoFactorAuthentication() {
    const response = await api.get("/two-factor-authentication");
    return response.data;
  }
  public async twoFactorAuthenticationPost(data: any) {
    const response = await api.post("/two-factor-authentication", data);
    return response.data;
  }

  public async socialAuthentication(data: any) {
    const response = await api.patch(`/users/toggleSocialAuthentication`, data);
    return response.data;
  }
  public async deleteTwoFaAuth(id: any) {
    const response = await api.delete(`/two-factor-authentication/${id}`);
    return response;
  }
  public async updateTwoFaAuth(id: any) {
    const response = await api.patch(`/two-factor-authentication/${id}`);
    return response;
  }
  public async twoFactorAuthenticationVerifyPhone(data: any) {
    const response = await api.post(
      "/two-factor-authentication/verifyPhone/v2",
      data
    );
    return response.data;
  }
  public async updateTwoFaAuthStatus() {
    const response = await api.patch(`two-factor-authentication/toggle`);
    return response;
  }
  public async verifyCurrentPassword(data: any) {
    const response = await api.patch(`/users/verifyChangePassword`, data);
    return response.data;
  }
  public async getStocksDetail(stockId: string) {
    const response = await api.get(`/stocks/detail?stockId=${stockId}`);
    return response;
  }

  public async getComparisonData(symbol: string, ticker2: string) {
    const response = await api.get(
      `/plot_price_comparison?ticker_1=${symbol}&ticker_2=${ticker2}&days_back=365`
    );
    return response;
  }

  public async getPricePerformance(stockId: string) {
    const response = await api.get(`/stockdetail/priceperformance/${stockId}`);
    return response;
  }
  public async getShareStats(stockId: string) {
    const response = await api.get(`/stockdetail/shareStats/${stockId}`);
    return response;
  }
  public async getFundamentals(stockId: string) {
    const response = await api.get(`/stockdetail/fundamentals/${stockId}`);
    return response;
  }
  public async getRange(stockId: string) {
    const response = await api.get(`/stockdetail/range/${stockId}`);
    return response;
  }
  public async getRelatedNews(stockId: string) {
    const response = await api.get(`/stockdetail/relatedNews/${stockId}`);
    return response;
  }
  public async getPrediction(data: any) {
    const response = await api.post(`/stockdetail/prediction`, data);
    return response;
  }
  public async getRating(stockId: string) {
    const response = await api.get(`/stockdetail/rating/${stockId}`);
    return response;
  }

  public async getTechnicalIndicator(data: any) {
    const response = await api.post(`/stockdetail/technicalIndicator`, data);
    return response;
  }
  public async searchStockBySymbol(keyword: string) {
    const response = await api.get(`/stocks/search/symbols?keyword=${keyword}`);
    return response;
  }

  public async getStockNews(symbol: string, market: string) {
    const response = await api.get(
      `news/stock?symbol=${symbol}&market=${market}`
    );
    return response;
  }

  public async updatePlaybookV2(id: any, data: any) {
    const response = await api.patch(`/playbook-v2/${id}`, data);
    return response.data;
  }

  public async createPlaybookV2(data: any) {
    const response = await api.post(`/playbook-v2`, data);
    return response.data;
  }

  public async deletePlaybookV2(id: any) {
    const response = await api.delete(`/playbook-v2/${id}`);
    return response;
  }

  public async getAllPlaybookByUserId() {
    const response = await api.get(`/playbook-v2/playbookForDataUserId`);
    return response.data;
  }

  public async updateMutiplePlaybookV2(data: any) {
    const response = await api.patch(`/playbook-v2/multiple`, data);
    return response.data;
  }

  public async fetchMetaImage(url: string) {
    const response = await api.post(`/meta-validator/fetchImage`, { url });
    return response.data;
  }

  public async getMetaTitle(data: any) {
    const response = await api.post(`/meta-validator`, data);
    return response.data;
  }

  // Feed Work //

  public async getURlMetaData(url: string) {
    const response = await api.post(`/posts/metadata`, { url: url });
    return response;
  }

  public async getUsers(keyword: string) {
    const response = await api.get("/users?keyword=" + keyword);
    return response;
  }

  public async searchStockCrypto(keyword: string = "", limit: number = -1) {
    var query = "?";
    if (keyword) {
      query += `keyword=${keyword}`;
    }
    if (limit != -1) {
      query += `&limit=${limit}`;
    }
    let response = await api.get("/cache-service/search/all" + query);
    return response;
  }

  public async searchCryptoSearch(
    type: any,
    keyword: string = "",
    limit: number = -1
  ) {
    var query = "?";
    if (keyword) {
      query += `keyword=${keyword}`;
    }
    if (limit != -1) {
      query += `&limit=${limit}`;
    }
    let response = await api.get(`/cache-service/search/${type}` + query);
    return response;
  }

  public async searchStockSymbol(keyword: string = "") {
    var query = "?";
    if (keyword) {
      query += `keyword=${keyword}`;
    }
    let response = await api.get(`/stocks/search/symbols?keyword=${keyword}`);
    return response;
  }

  public async searchStockSearch(
    type: any,
    keyword: string = "",
    limit: number = -1
  ) {
    var query = "?";
    if (keyword) {
      query += `keyword=${keyword}`;
    }
    if (limit != -1) {
      query += `&limit=${limit}`;
    }
    let response = await api.get(`/cache-service/search/${type}` + query);
    return response;
  }

  public async addPost(data: PostsModel) {
    const response = await api.post(`/posts`, data);
    return response;
  }

  public async updatePost(data: UpdatePostModel) {
    const response = await api.put(`/posts`, data);
    return response;
  }
  public async uploadPdf(data: File | FormData) {
    const response = await api.post(`/posts/pdf`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
  }

  public async deletePost(paylaod: any) {
    const response = await api.delete(`/posts`, { data: paylaod });
    return response;
  }

  public async getPublicPostById(postId: any) {
    const response = await api.get(`/external/post/${postId}`);
    return response.data;
  }

  public async votePollStock(data: any) {
    const response = await api.post(`/posts/voteonpollchart`, data);
    return response;
  }

  public async getTopCrypto() {
    const response = await api.get(`/crypto/top`);
    return response;
  }

  public async addComment(data: CommentsModel) {
    const response = await api.post("/posts/comment", data);
    return response;
  }

  public async removeComment(id: string) {
    const response = await api.delete(`/posts/comment/${id}`);
    return response;
  }

  public async searchStock(keyword: string = "", limit: number = -1) {
    var query = "?";
    if (keyword) {
      query += `keyword=${keyword}`;
    }
    if (limit != -1) {
      query += `&limit=${limit}`;
    }
    let response = await cacheApi.get("/search/stock" + query);
    return response;
  }

  public async searchCrypto(keyword: string = "", limit: number = -1) {
    var query = "?";
    if (keyword) {
      query += `keyword=${keyword}`;
    }
    if (limit != -1) {
      query += `&limit=${limit}`;
    }
    let response = await cacheApi.get("/search/crypto" + query);
    return response;
  }

  public async searchStockForPost(keyword: string = "", limit: number = -1) {
    var query = "?";
    if (keyword) {
      query += `keyword=${keyword}`;
    }
    if (limit != -1) {
      query += `&limit=${limit}`;
    }
    let response = await api.get("/cache-service/search/stock" + query);
    return response;
  }

  public async searchCryptoForPost(keyword: string = "", limit: number = -1) {
    var query = "?";
    if (keyword) {
      query += `keyword=${keyword}`;
    }
    if (limit != -1) {
      query += `&limit=${limit}`;
    }
    let response = await api.get("/cache-service/search/crypto" + query);
    return response;
  }

  public async getData(url: string) {
    const response = await api.get(url);
    return response;
  }

  public async handleDataForAssets(data: any) {
    let response = await api.post(`/savedassets`, data);
    return response;
  }

  public async deleteDataFromAssets(id: any) {
    let response = await api.delete(`/savedassets?assetId=${id}`, {});
    return response;
  }

  public async getSavedAssets(filterType: any) {
    let response = await api.get(`/savedassets?filterType=${filterType}`);
    return response;
  }

  public async likePost(likePostModel: LikePostModel) {
    const response = await api.post(`/posts/like`, likePostModel);
    return response;
  }

  public async unlikePost(unlikePostModel: UnlikeModel) {
    const response = await api.post(`/posts/unlike`, unlikePostModel);
    return response;
  }

  public async getStocks() {
    const response = await api.get(`/stocks`);
    return response;
  }

  public async getCryptos(apiUrl: any) {
    let response = await api.get(`/crypto/header?symbol=${apiUrl}`);
    return response;
  }

  public async getstocks(symbols: string) {
    let queryparams = `symbol=${symbols}&column=all`;
    const response = await api.get(`/stocks/live?${queryparams}`);
    return response;
  }
  public async submitBugReport(data: any) {
    let response = await api.post(`/reported-bug`, data);
    return response;
  }
  public async addEtradeAccount(accountId: string) {
    const response = await api.post(`/etrade/portfolio/${accountId}`);
    return response;
  }
  public async getEtradeAccountBalance(accountId: string) {
    const response = await api.get(
      `/etrade/account/balance?accountId=${accountId}`
    );
    return response;
  }
  public async authenticateEtrade(oAuthToken: string, oAuthVerifier: string) {
    const response = await api.post(`/etrade/authenticate`, {
      oAuthToken,
      oAuthVerifier,
    });
    return response;
  }
  public async GetPortgoliosTransactionListing(id: string) {
    let response = await api.get(`/portfolio/transactions/${id}`);
    return response;
  }
  public async deletePortfolio(id: string) {
    let response = await api.delete(`/portfolio?portfolioId=${id}`);
    return response;
  }
  public async GetPortfolioSellItems(id: string) {
    let response = await api.get(`/portfolio/sellItems/${id}`);
    return response;
  }
  // public async AddPortfolio(portfolio: any) {
  //   let response = await api.post(`/portfolio`, portfolio);
  //   return response;
  // }

  public async AddPortfolio(portfolio: any) {
    let response = await api.post(`/portfolio-v2`, portfolio);
    return response;
  }

  public async updatePortfolio(portfolioId: string, updateBody: any) {
    let response = await api.patch(`/portfolio/${portfolioId}`, updateBody);
    return response;
  }
  public async AddItemsToPortfolio(dataArray: any) {
    let response = await api.post(`/portfolio/addUpdateItems`, dataArray);
    return response;
  }
  public async getPurchasedPortfolios(userID: string) {
    let response = await api.get(`/portfolio/purchased/${userID}`);
    return response;
  }
  public async getPortfoliosListingByUserId(userId: string) {
    let response = await api.get(`/portfolio/byUser/${userId}`);
    return response;
  }
  public async GetPortoliosHoldingsListing(id: string) {
    let response = await api.get(`/portfolio/holding/${id}`);
    return response;
  }
  public async GetPortoliosSummaryListing(id: string) {
    let response = await api.get(`/portfolio/summary/${id}`);
    return response;
  }
  // public async GetPortgoliosListing() {
  //   let response = await api.get(`/portfolio`);
  //   return response;
  // }
  public async GetPortgoliosListing() {
    let response = await api.get(`/portfolio-v2`);
    return response;
  }

  public async GetChartsForPortfolioLineItems() {
    let response = await api.get(`/portfolio/dataForCharts`);
    return response;
  }
  public async GetDataForSelectedPortfoliosPerformance(data: any) {
    let response = await api.post(`/portfolio/dataForPortolfiosChart`, data);
    return response;
  }
  public async getEtradeAuthURL() {
    const response = await api.get(`/etrade/authUrl`);
    return response;
  }
  public async getWatchList() {
    const response = await api.get("/watchlist");
    return response;
  }
  public async loadWatchlist(watchlistId: string) {
    let repsonse = await api.post(`/watchlist/load`, {
      watchlistId: watchlistId,
    });
    return repsonse;
  }
  public async snapTradeConnection() {
    const response = await api.get("/snaptrade/connectionLink");
    return response.data;
  }
  public async loadBrokerageAuthorizations() {
    const response = await api.get("/snaptrade/brokerageAuthorizations");
    return response.data;
  }
  public async loadsnapTradeAccounts(id: number) {
    const response = await api.get(
      `/snaptrade/accounts?brokerageAuthorizationId=${id}`
    );
    return response;
  }
  public async loadSnapTradePortfolio(id: any) {
    let response = await api.post(`/snaptrade/portfolio/${id}`);
    return response;
  }

  public async getSystemConfiguration(keyword: string) {
    const response = await api.get(`/system-config?type=${keyword}`);
    return response.data;
  }

  public async updateConnectionType(connectionId: any, relationType: any) {
    const response = await api.patch(`/connections/relation/${connectionId}`, {
      relationType: relationType,
    });
    return response;
  }

  public async getByUsername(userName: string) {
    const response = await api.get(`/users/${userName}/username`);
    return response;
  }

  public async getTopFriends(userId: string) {
    const response = await api.get(`/topfriends/${userId}`);
    return response;
  }

  public async getConnections(connectionType: string, userId?: string) {
    const response = await api.get(
      `/connections?connectionType=${connectionType}&userId=${userId}`
    );
    return response;
  }

  public async getWatchListByUserId(userId: string) {
    const response = await api.get("/watchlist/user/" + userId);
    return response;
  }

  public async getConnectionFriendReqStatus(profileId: string) {
    const response = await api.get(
      `/connections/connectionStatus/${profileId}`
    );
    return response;
  }

  public async getpublicPapers(userId: any) {
    let response = await api.get(`/papers/public/${userId}`);
    return response;
  }

  public async getConnectionsCount(userId: string) {
    const response = await api.get("/connections/count?userId=" + userId);
    return response;
  }

  public async authenticatedUserFriends(data: any) {
    const response = await api.post(`/connections/authenticated`, data);
    return response;
  }

  public async getDictionaryBykeyword(keyword: string) {
    const response = await api.get(`/dictionary/search/${keyword}`);
    return response.data;
  }

  public async searchTopFriends(userId: string, keyword: string) {
    const response = await api.get(
      `/topfriends/${userId}/search?keyword=${keyword}`
    );
    return response;
  }

  public async addTopFriend(topFriend: AddTopFriendDTO) {
    const response = await api.post(`/topfriends`, topFriend);
    return response;
  }
  public async checkIfFollowStockExist(data: any) {
    let repsonse = await api.post(`/stocks/checkifexist`, data);
    return repsonse;
  }
  public async unfollowStocks(data: any) {
    let repsonse = await api.post(`/stocks/unfollow`, data);
    return repsonse;
  }
  public async followStocks(data: FollowStockModel) {
    let repsonse = await api.post(`/stocks/follow`, data);
    return repsonse;
  }

  public async deleteTopFriend(topFriend: AddTopFriendDTO) {
    const response = await api.delete(`/topfriends`, { data: topFriend });
    return response;
  }

  public async filterUserSearch(query: string) {
    const response = await api.get(
      `/leaderboard/filterUser?type=all&page=1&count=10&keyword=${query}`
    );
    return response;
  }

  public async getReportedBugs(page: string = "1", pageSize: string = "10") {
    var query = "?";
    if (page) {
      query += `page=${page}`;
    }
    if (pageSize) {
      query += `&pageSize=${pageSize}`;
    }
    return api.get(`/reported-bug${query}`);
  }

  public async socialAccountVerification(data: SocialAccountVerificationDTO) {
    const response = await api.post(`/social-accounts-verification`, data);
    return response;
  }

  public async logoutUser() {
    const response = await api.post("/users/logout");
    return response.data;
  }

  public async blockUser(data: any) {
    const response = await api.post(`/users/block`, data);
    return response;
  }

  public async unBlockUser(data: any) {
    const response = await api.post(`/users/unblock`, data);
    return response;
  }

  public async getProfileRelations() {
    const response = await api.get(`/profile/relation`);
    return response;
  }

  public async getfinancialChart(stockId: string) {
    const response = await api.get(`/stockdetail/financialChart/${stockId}`);
    return response;
  }
  public async getMainguage(stockId: string) {
    const response = await api.get(`/stockdetail/mainguages/${stockId}`);
    return response;
  }
  public async getAllguages(stockId: string) {
    const response = await api.get(`/stockdetail/allguages/${stockId}`);
    return response;
  }
  public async getCompanyDetails(stockId: string) {
    const response = await api.get(`/stockdetail/companyDetails/${stockId}`);
    return response;
  }
  public async getSecurityShortVolume(stockId: string) {
    const response = await api.get(
      `/stockdetail/securityShortVolume/${stockId}`
    );
    return response;
  }
  public async getInsiderTrades(stockId: string) {
    const response = await api.get(`/stockdetail/insiderTrades/${stockId}`);
    return response;
  }

  public async getFinancialSummary(stockId: string) {
    const response = await api.get(`/stockdetail/financialSummary/${stockId}`);
    return response;
  }
  public async getPlaidConnectionLink() {
    return await api.get(`/plaid/connectionLink`);
  }
  public async addPlaidBrokerageData(body: any) {
    return await api.post(`/plaid/brokerageAuthorization`, body);
  }
  public async getPlaidBrokerageData() {
    return await api.get(`/plaid/brokerageAuthorizations`);
  }

  public async addPlaidPortfolio(brokerageId: string) {
    return await api.post(`/plaid/portfolio/${brokerageId}`);
  }

  public async getUserPhotos(userId: any) {
    return await api.get(`/users/photos/${userId}`);
  }

  public async getCompanies() {
    const response = await api.get("companies");
    return response;
  }

  public async addwidgetconfiguration(widget: any) {
    const response = await api.post("/widgetconfiguration", widget);
    return response;
  }

  public async getLeaderboard(query: any) {
    const response = await api.get(`/leaderboard/leaderboard${query}`);
    return response;
  }

  public async getLeaderboardTrade(query: any) {
    const response = await api.get(`/leaderboard/trade${query}`);
    return response;
  }

  public async getLeaderboardWidget(query: any) {
    const response = await api.get(`/leaderboard${query}`);
    return response;
  }

  public async addPostsTrade(data: any) {
    const response = await api.post(`/posts/trade`, data);
    return response.data;
  }

  public async addPredictionTimeline(data: any) {
    const response = await api.post(`/prediction/predictiontimeline`, data);
    return response.data;
  }

  public async tradeLivePrice(data: any) {
    const response = await api.post(`/posts/liveprice`, data);
    console.log("🚀 ~ HttpService ~ tradeLivePrice ~ response:", response);
    return response.data;
  }

  public async searchSubscriptions(keyword: string) {
    const response = await api.get(
      `/portfolio/subscriptions?keyword=${keyword}`
    );
    return response.data;
  }

  public async searchConnectionByType(connectionType: string, keyword: string) {
    const response = await api.get(
      `/connections/search/${connectionType}?keyword=${keyword}`
    );
    return response;
  }

  public async getWidgetsConfig() {
    const response = await api.get(`/widgetconfiguration?pageTitle=dashboard`);
    return response;
  }

  public async getAllStocksFilterCount(filter: any) {
    const response = await api.get(`/stocks/stocksCount?type=${filter}`);
    return response.data;
  }

  public async getAllStocksWithFilter(query: any) {
    const response = await api.get(`/stocks/type${query}`);
    return response.data;
  }

  public async getAllStocksCountFilter() {
    const response = await api.get(`/favourite-investment/counts`);
    return response.data;
  }

  public async getEquityShares(portfolioId) {
    const response = await api.get(`/portfolio/equity-shares/${portfolioId}`);
    return response.data;
  }

  public async getStats(portfolioId) {
    const response = await api.get(`/portfolio/stats/${portfolioId}`);
    return response.data;
  }

  public async getRankings(portfolioId) {
    const response = await api.get(`/portfolio/rankings/${portfolioId}`);
    return response.data;
  }

  public async getDistributions(portfolioId: string) {
    const response = await api.get(`/portfolio/distributions/${portfolioId}`);
    return response.data;
  }

  public async cummulativeReturn(portfolioId) {
    const response = await api.get(
      `/portfolio/cummulative-return/${portfolioId}`
    );
    return response;
  }
  
  public async getProfileStats(portfolioId) {
    const response = await api.get(`/portfolio/performanceStats/${portfolioId}`);
    return response.data;
  }
  public async weightedReturn(portfolioId) {
    const response = await api.get(
      `/portfolio/weightedReturn/${portfolioId}`
    );
    return response.data.data;
  }

  public async heatMap(portfolioId) {
    const response = await api.get(
      `/portfolio/heatMap/${portfolioId}`
    );
    return response.data.data;
  }

  public async symbolGet(symbol) {
    const response = await api.get(
      `/stocks/symbolInfo?symbol=${symbol}`
    );
    return response.data.data;
  }

  public async createMarketTrade(data: any) {
    const response = await api.post("/portfolio-trade/create/market", data);
    return response.data;
  }

  public async createLimitTrade(data: any) {
    const response = await api.post("/portfolio-trade/create/limit", data);
    return response.data;
  }

  public async etfChart(portfolioId) {
    const response = await api.get(
      `/portfolio/compositionChart/${portfolioId}`
    );
    return response.data.data;
  }

  public async getPortfolioOrdersTable(portfolioId: string, status: string) {
    const response = await api.get("/portfolio-trade", {
      params: {
        portfolio_id: portfolioId,
        status: status
      }
    });
    return response.data;
  }

  public async getPortfolioOrdersTableAll(portfolioId: string) {
    const response = await api.get("/portfolio-trade", {
      params: {
        portfolio_id: portfolioId,
      }
    });
    return response.data;
  }

  public async getPortfolioTransactionsTable(portfolioId) {
    const response = await api.get(
      `/portfolio/allTransactions/${portfolioId}`
    );
    return response.data.data;
  }

  public async getTicketDetail(ticketToken: string, email: string) {
    const response = await api.get(`/ticket`, {
      params: { ticketToken, email }
    });
    return response.data;
  }

  public async markViewed() {
    const response = await api.post(`/connections/markviewed`);
    return response;
  }

  public async updateConnection(connectionId: string, status: string) {
    const response = await api.put(
      `/connections/${connectionId}/status/${status}`
    );
    return response;
  }

  public async getCurrentUser() {
    const response = await api.get(`/users/me`);
    return response.data;
  }


}


export default new HttpService();
