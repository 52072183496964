export enum StorageKeys {
  user = "user",
  token = "token",
  isFirebaseLogin = "isFirebaseLogin",
  sideMenuvalue = "sideMenuValue",
  rememberMe = "rememberMe",
  deviceId = "deviceId",
  sharingObject = "sharingObject",
  systemConfiguration = "systemConfiguration",
  deletedPosts = "deleted-posts",
  fcmToken = "fcmToken",
  sessionId = "sessionId",
  dummySingup = 'dummySingup',
  deletedChatId = "deleted-chats",
}

export enum Reactions {
  like = "likeicon01.png",
  love = "likeicon02.png",
  blessed = "likeicon03.png",
  smooch = "likeicon04.png",
}

export enum ValidSortFilter {
  followersCount = "followersCount",
  followingCount = "followingCount",
  viewsCount = "viewsCount",
  diversification = "diversification",
  loss = "loss",
  gain = "gain",
  investments = "investments",
  trades = "trades",
  topGain = "topGainPrice",
  topLoss = "topLoss",
  avgGain = "avgGainPrice",
  avgLoss = "avgLoss",
  outreach = "outreach",
}

export enum ShareType {
  email = "email",
  message = "message",
  messenger = "messenger",
  facebook = "facebook",
  twitter = "twitter",
  x = "x",
  linkedin = "linkedin",
  instagram = "instagram",
  whatsapp = "whatsapp",
  slack = "slack",
  discord = "discord",
  telegram = "telegram",
  reddit = "reddit",
  copy = "copy",
  copyImg = "copyImg",
  copyUrl = "copyUrl",
}
