import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import { RouteEnum } from "./routeNames";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    redirect: { name: "signin" },
  },
  {
    path: "/not-found",
    name: "notFound",
    component: () => import("../views/NotFound.vue"),
  },
  {
    path: "/signin",
    name: "signin",
    component: () => import("../views/public/Signin.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../views/public/Signup.vue"),
  },
  {
    path: "/signup/:id",
    name: "signupWaitlist",
    component: () => import("../views/public/Signup.vue"),
  },
  {
    path: "/resetpassword",
    name: "resetPassword",
    component: () => import("../views/public/ResetPassword.vue"),
  },
  {
    path: "/approveAccess",
    name: "approveAccess",
    component: () => import("../views/public/ApproveAccess.vue"),
  },
  {
    path: "/verifyemail",
    name: "verifyEmail",
    component: () => import("../views/public/VerifyEmail.vue"),
  },
  {
    path: "/otp-verification",
    name: "otpVerification",
    component: () => import("../views/public/VerificationOTP.vue"),
  },
  {
    path: "/forget-password",
    name: "forgetPassword",
    component: () => import("../views/public/ForgetPassword.vue"),
  },
  {
    path: "/locked",
    name: "locked",
    component: () => import("../views/public/Locked.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/private/test.vue"),
  },
  {
    path: "/ticketDetail",
    name: "ticketDetail",
    component: () => import("../views/private/ticketDetail.vue"),
  },
  {
    path: "/private/_/_O_AUTH_/_twitter_v2",
    name: "twitter",
    component: () =>
      import("../components/AddShareComponent/TwitterSuccessfull.vue"),
  },
  {
    path: "/private/_O_AUTH/_linked_in",
    name: "linkedIn",
    component: () =>
      import("../components/AddShareComponent/TwitterSuccessfull.vue"),
  },
  {
    path: "/private/_O_AUTH/_reddit",
    name: "reddit",
    component: () =>
      import("../components/AddShareComponent/TwitterSuccessfull.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    meta: { showSideBar: true, showHeader: true },
    component: () => import("../views/private/Private.vue"),
    redirect: { name: "feed" },
    children: [
      {
        path: "dashboard",
        name: RouteEnum.profileDashboard,
        meta: { requiresAuth: true, showSideBar: true, showHeader: true },
        component: () => import("../views/private/dashboard/Dashboard.vue"),
      },
      {
        path: "feed",
        name: "feed",
        meta: { requiresAuth: true, showSideBar: true, showHeader: true },
        component: () => import("../views/private/timeline/timeline.vue"),
      },
      {
        path: "markets",
        name: "markets",
        meta: { requiresAuth: true, showSideBar: true, showHeader: true },
        component: () => import("../views/private/markets/markets.vue"),
      },
      {
        path: "feed/:postId",
        name: "postDetail",
        meta: { showSideBar: true, showHeader: true, showComment: true },
        component: () => import("../views/private/timeline/timeline.vue"),
      },
      {
        path: "create",
        meta: {
          requiresAuth: true,
          showSideBar: false,
          showHeader: false,
          showComment: false,
        },
        name: "createProfile",
        component: () =>
          import("../views/private/create-profile/CreateProfile.vue"),
      },
      {
        path: "qr-code",
        meta: { requiresAuth: true },
        name: "qrCode",
        component: () => import("../views/private/QRCode/QRCode.vue"),
      },
      {
        path: "settings",
        meta: { requiresAuth: true, showSideBar: true, showHeader: true },
        name: "settings",
        component: () => import("../views/private/settings/settings.vue"),
      },

      {
        path: "analyze",
        meta: { requiresAuth: true, showSideBar: true, showHeader: true },
        name: "analyze",
        component: () => import("../views/private/analyze/analyze.vue"),
      },

      {
        path: "explore",
        meta: { requiresAuth: true, showSideBar: true, showHeader: true },
        name: "explore",
        component: () => import("../views/private/explore/explore.vue"),
      },

      {
        path: "marketPlace",
        meta: { requiresAuth: true, showSideBar: true, showHeader: true },
        name: "marketPlace",
        component: () => import("../views/private/marketPlace/marketPlace.vue"),
      },

      {
        path: "social",
        meta: { requiresAuth: true, showSideBar: true, showHeader: true },
        name: "social",
        component: () => import("../views/private/social/social.vue"),
      },
      {
        path: "market",
        meta: { requiresAuth: true, showSideBar: true, showHeader: true },
        name: "market",
        component: () => import("../views/private/market/market.vue"),
      },
      {
        path: "leaderboard",
        meta: { requiresAuth: true, showSideBar: true, showHeader: true },
        name: "leaderboard",
        component: () => import("../views/private/leaderboard/leaderboard.vue"),
      },
      {
        path: "articles_overview",
        meta: { requiresAuth: true, showSideBar: true, showHeader: true },
        name: "articles_overview",
        component: () => import("../views/private/articles/overview.vue"),
      },
      {
        path: "followers/:userId",
        meta: { requiresAuth: true, showSideBar: true, showHeader: true },
        name: "profileFollowers",
        component: () => import("../views/private/followers/Followers.vue"),
      },
      {
        path: "/stock/:stockId",
        name: "stockDetails",
        // component: () =>
        //   import("../views/private/stock-details/stock-details.vue"),
        meta: { requiresAuth: true, showSideBar: true, showHeader: true },
        component: () =>
          import("../views/private/stocks/stocks.vue"),
      },
      {
        path: "/crypto/:cryptoId",
        name: "cryptoDetails",
        // component: () =>
        //   import("../views/private/stock-details/stock-details.vue"),
        meta: { requiresAuth: true, showSideBar: true, showHeader: true },
        component: () =>
          import("../views/private/crypto/crypto.vue"),
      },
      {
        path: "following/:userId",
        meta: { requiresAuth: true },
        name: "profileFollowing",
        component: () => import("../views/private/following/following.vue"),
      },
      {
        path: "user/:username",
        name: RouteEnum.profile,
        component: () =>
          import("../views/private/profile-screen/ProfileScreen.vue"),
      },
      {
        path: "/stocks/:stockId",
        name: "stocks",
        meta: { requiresAuth: true, showSideBar: true, showHeader: true },
        component: () =>
          import("../views/private/stocks/stocks.vue"),
      },
      {
        path: "portfolio",
        name: "portfolio",
        meta: { requiresAuth: true, showSideBar: true, showHeader: true },
        component: () => import("../views/private/portfolio/PortfolioScreen.vue"),
        redirect: { name: "portfolio_tab_overview", params: { tab: "custom" } },
        children: [
          {
            path: ":tab",
            name: "portfolio_tab",
            redirect: { name: "portfolio_tab_inner", params: { tab: "custom" } },
          },
          {
            path: ":tab/overview",
            name: "portfolio_tab_overview",
            component: () => import("../views/private/portfolio/widgets/PortfolioOverview.vue"),
          },
          {
            path: ":tab/holdings",
            name: "portfolio_tab_holdings",
            component: () => import("../views/private/portfolio/widgets/PortfolioHoldings.vue"),
          },
          {
            path: ":tab/summary",
            name: "portfolio_tab_summary",
            component: () => import("../views/private/portfolio/widgets/PortfolioSummary.vue"),
          },
          {
            path: ":tab/transactions",
            name: "portfolio_tab_transactions",
            component: () => import("../views/private/portfolio/widgets/PortfolioTransactions.vue"),
          },
          {
            path: ":tab/analytics",
            name: "portfolio_tab_analytics",
            component: () => import("../views/private/portfolio/widgets/PortfolioAnalytics.vue"),
          },
        ],
      },
      {
        path: "widgets",
        meta: { requiresAuth: true, showSideBar: true, showHeader: true },
        name: "widgets",
        component: () => import("../views/private/widgets/Widgets.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 })
      }, 500)
    })
  },
});

export default router;
